export function separateNumber(input: number) {
  let nfObject = new Intl.NumberFormat("en-US");
  return nfObject.format(input);
}

export function roundTwoDecimalPlaces(input: number) {
  return Math.round(input * 100) / 100;
}

export function randomNumbers(digits: number): number {
  if (digits < 1) {
    throw new Error("Number of digits must be at least 1");
  }

  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function padNumberWithZeros(
  num: number,
  totalDigits: number = 7
): string {
  //adding zeros to the number
  const numStr = num.toString();
  if (numStr.length >= totalDigits) {
    return numStr;
  }
  const zerosToAdd = totalDigits - numStr.length;
  return "0".repeat(zerosToAdd) + numStr;
}

export const sumColumnFromObjectList = (
  arr: any[], //list of objects with columns to be summmed
  column_name: string | ((data: any) => number), //the name of column to be summed
  transform = (col: any) => parseFloat(col) //properly formatting that column
) => {
  if (Object.keys(arr).length === 0) return 0;
  return (arr || []).reduce(
    (a, b) =>
      a +
      transform(
        typeof column_name === "string"
          ? b[column_name]
          : typeof column_name === "function"
          ? column_name(b)
          : 0
      ),
    0
  );
};

export const code_rate = 0.18;

export const removeNonNumeric = (str?: string) => {
  if (!str) {
    return 0;
  }
  return str.replace(/[^0-9]/g, "");
};

export function k_m_formatNumber(num: number) {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return num.toString();
  }
}

export function isGreaterThan(
  first: string | number,
  second: string | number
): boolean {
  let decFirst = 0;
  let decSecond = 0;
  if (typeof first === "string") decFirst = parseInt(first);
  else decFirst = first;

  if (typeof second === "string") decSecond = parseInt(second);
  else decSecond = second;

  return decFirst > decSecond;
}
