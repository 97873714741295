import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword, resetPassword } from "../core/_requests";
import { complexError } from "../../../utils/general-utils";

type ResetType = {
  email: string;
  password?: string;
  confirm_password?: string;
};
const initialValues: ResetType = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email address is required"),

  password: Yup.string()
    .min(6, "Password should be atleast 6 characters")
    .max(50, "Password should be atmost 50 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .min(6, "Comfirmation Password should be atleast 6 characters")
    .max(50, "Comfirmation Password should be atmost 50 characters")
    .required("Comfirmation Password is required"),
});

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<string | "initial" | "no-errors">(
    "initial"
  );
  const { token } = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors("initial");

      resetPassword(
        values.email,
        values.password,
        values.confirm_password,
        token
      )
        .then((data) => {
          console.log(data);
          setHasErrors("no-errors");
          setLoading(false);
          // navigate("/login");
        })
        .catch((ex) => {
          console.log(ex);
          setHasErrors(complexError(ex));
          setLoading(false);
          setSubmitting(false);
          setStatus("The login detail is incorrect");
        });
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-700 fw-semibold fs-6">Enter new password</div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors.length &&
      hasErrors !== "initial" &&
      hasErrors !== "no-errors" ? (
        <>
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{hasErrors}</div>
          </div>
        </>
      ) : (
        <></>
      )}

      {hasErrors === "no-errors" ? (
        <>
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">Password reset is successful</div>
          </div>
          <NavLink to="/auth/login" className={"btn btn-success btn-lg"}>
            Go to Login
          </NavLink>
        </>
      ) : (
        <></>
      )}
      {/* end::Title */}
      {hasErrors === "initial" ||
      (hasErrors.length && hasErrors !== "no-errors") ? (
        <>
          {/* begin::Form group */}
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Email
            </label>
            <input
              type="email"
              placeholder=""
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-solid",
                { "is-invalid": formik.touched.email && formik.errors.email },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Password
            </label>
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Confirm Password
            </label>
            <input
              type="password"
              placeholder="Confirm Password"
              autoComplete="off"
              {...formik.getFieldProps("confirm_password")}
              className={clsx(
                "form-control bg-solid",
                {
                  "is-invalid":
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password,
                },
                {
                  "is-valid":
                    formik.touched.confirm_password &&
                    !formik.errors.confirm_password,
                }
              )}
            />
            {formik.touched.confirm_password && formik.errors.confirm_password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.confirm_password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button
              type="submit"
              id="kt_password_reset_submit"
              className="btn btn-danger me-4"
            >
              {loading ? (
                <>
                  <span>Please wait...</span>
                  <span className="indicator-progress">
                    <span className="spinner-border text-white spinner-border-sm align-middle ms-2"></span>
                  </span>
                </>
              ) : (
                <span className="indicator-label">Submit</span>
              )}
            </button>
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_password_reset_form_cancel_button"
                className="btn btn-light"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* end::Form group */}
    </form>
  );
}
