import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { Suspense, lazy } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";
import SidebarMenuItemWithSub from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import PurchasesView from "./components/purchases/purchase-view";

const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "Inventory",
    path: "/inventory/inventory-dashboard",
    isSeparator: false,
    isActive: false,
  },

  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const InventoryPage = () => {
  const InventoryDashboard = lazy(
    () => import("./components/inventory-dashboard")
  );

  const ProductsAndServices = lazy(
    () => import("./components/products-and-services/products-and-services")
  );
  const LowProductReport = lazy(
    () => import("./components/low-product-report")
  );
  const RequisitionOrderReport = lazy(
    () => import("./components/requisition-order-report")
  );
  const StockTransfers = lazy(
    () => import("./components/stock-transfer/stock-transfers")
  );
  const StockRequisition = lazy(() => import("./components/stock-requisition"));
  const InventoryMovementReport = lazy(
    () => import("./components/inventory-movement-report")
  );
  const StockValue = lazy(() => import("./components/stock-value"));
  const ItemizedReport = lazy(() => import("./components/itemized-report"));
  const FixedAssets = lazy(
    () => import("./components/fixed-assets/fixed-assets")
  );
  const FixedAssetView = lazy(
    () => import("./components/fixed-assets/fixed-asset-view")
  );
  const FixedAssetsClasses = lazy(
    () => import("./components/fixed-assets/fixed-assets-classes")
  );
  const FixedAssetsDocument = lazy(
    () => import("./components/fixed-assets/fixed-assets-document")
  );
  const FixedAssetsDocumentView = lazy(
    () => import("./components/fixed-assets/fixed-asset-document-view")
  );

  const PreConfiguredUnits = lazy(
    () => import("./components/pre-configured/pre-configured-units")
  );
  const SimCards = lazy(() => import("./components/sim/sim-cards"));
  const PurchasesDashboard = lazy(
    () => import("./components/purchases/purchases-dashboard")
  );
  const PurchasesOrders = lazy(
    () => import("./components/purchases/purchases-orders")
  );
  const PurchasesReport = lazy(
    () => import("./components/purchases/purchases-report")
  );
  const ProductsAndServicesShow = lazy(
    () => import("./components/products-and-services-show")
  );
  const PurchasesView = lazy(
    () => import("./components/purchases/purchase-view")
  );

  const UnitList = lazy(() => import("./components/units/units-list"));
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Inventory Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <InventoryDashboard />
              </Suspense>
            </>
          }
        />
        <Route
          path="inventory-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Inventory Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <InventoryDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="products-and-services"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Products &amp; Services
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ProductsAndServices />
              </Suspense>
            </>
          }
        />

        <Route
          path="products-and-services/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Products &amp; Services Show
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ProductsAndServicesShow />
              </Suspense>
            </>
          }
        />

        <Route
          path="low-product-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Low Product Report
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <LowProductReport />
              </Suspense>
            </>
          }
        />

        <Route
          path="requisition-order-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Requisition Order Report
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <RequisitionOrderReport />
              </Suspense>
            </>
          }
        />

        <Route
          path="stock-transfers"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Stock Transfers
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <StockTransfers />
              </Suspense>
            </>
          }
        />

        <Route
          path="requisitions"
          element={
            <Suspense fallback={<SimpleLoader />}>
              <StockRequisition />
            </Suspense>
          }
        />

        <Route
          path="pre-configured-units"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Pre-configured Units
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <PreConfiguredUnits />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="sim-cards"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Sim Cards
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <SimCards />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="units"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Units</PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <UnitList />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="inventory-movement-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Inventory Movement Report
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <InventoryMovementReport />
              </Suspense>
            </>
          }
        />

        <Route
          path="stock-value"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Stock Value
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <StockValue />
              </Suspense>
            </>
          }
        />

        <Route
          path="itemized-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                ItemizedReport
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ItemizedReport />
              </Suspense>
            </>
          }
        />

        <Route
          path="fixed-assets"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Fixed Assets
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FixedAssets />
              </Suspense>
            </>
          }
        />

        <Route
          path="fixed-assets/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Fixed Assets View
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FixedAssetView />
              </Suspense>
            </>
          }
        />

        <Route
          path="fixed-asset-classes"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Fixed Assets Classes
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FixedAssetsClasses />
              </Suspense>
            </>
          }
        />

        <Route
          path="fixed-asset-document"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Fixed Assets Document
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FixedAssetsDocument />
              </Suspense>
            </>
          }
        />

        <Route
          path="fixed-asset-document/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Fixed Assets Document
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FixedAssetsDocumentView />
              </Suspense>
            </>
          }
        />

        <Route
          index
          element={<Navigate to="/inventory/inventory-dashboard" />}
        />

        <Route
          path="purchases-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Purchases Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PurchasesDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="purchases"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Purchases Orders
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PurchasesOrders />
              </Suspense>
            </>
          }
        />

        <Route
          path="purchases/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Purchase View
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PurchasesView />
              </Suspense>
            </>
          }
        />

        <Route
          path="purchases-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Purchases Report
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PurchasesReport />
              </Suspense>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default InventoryPage;

//============== THIS MODULES ROUTES =================
export const InventoryRoutes = () => (
  <SidebarMenuItemWithSub
    to="/inventory"
    title="Inventory"
    fontIcon="bi-sticky"
    icon="barcode"
  >
    {/* <SidebarMenuItem
      to="/inventory/inventory-dashboard"
      title="Inventory Dashboard"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    <SidebarMenuItem
      to="/inventory/products-and-services"
      title="Products &amp; Services"
      icon="parcel"
      // fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/purchases"
      title="Purchases"
      icon="logistic"
      // fontIcon="bi-person"
    />

    {/* <SidebarMenuItem
      to="/inventory/low-product-report"
      title="Low Product Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/inventory/requisition-order-report"
      title="Requisition Order Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    <SidebarMenuItem
      to="/inventory/stock-transfers"
      title="Stock Transfers"
      icon="delivery-3"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/requisitions"
      title="Requisitions"
      icon="courier-express"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/sim-cards"
      title="Sim Cards"
      icon="simcard"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/units"
      title="Units"
      icon="abstract-30"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/pre-configured-units"
      title="Pre-configured Units"
      icon="devices"
      fontIcon="bi-person"
    />

    {/* <SidebarMenuItem
      to="/inventory/inventory-movement-report"
      title="Inventory Movement Report"
      icon="credit-cart"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/stock-value"
      title="Stock Value"
      icon="credit-cart"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/inventory/itemized-report"
      title="ItemizedReport"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    <FixedAssetsRoutes />
    {/* <PurchasesRoutes /> */}
  </SidebarMenuItemWithSub>
);

const FixedAssetsRoutes = () => {
  return (
    <SidebarMenuItemWithSub
      to="/inventory"
      title="Fixed Assets"
      icon="cube-3"
      // fontIcon="bi-sticky"
    >
      <SidebarMenuItem
        to="/inventory/fixed-assets"
        title="Register"
        icon="questionnaire-tablet"
        // fontIcon="bi-person"
      />

      {/* This was moved to Settings > Inventory > Fixed Asset Classes      
      <SidebarMenuItem
        to="/inventory/fixed-asset-classes"
        title="Fixed Assets Classes"
        icon="credit-cart"
        fontIcon="bi-person"
      /> */}

      <SidebarMenuItem
        to="/inventory/fixed-asset-document"
        title="Sale & Disposal"
        icon="delete-files"
        // fontIcon="bi-person"
      />
    </SidebarMenuItemWithSub>
  );
};

export const PurchasesRoutes = () => (
  <SidebarMenuItemWithSub
    to="/inventory"
    title="Purchases"
    fontIcon="bi-sticky"
    icon="folder"
  >
    {/* <SidebarMenuItem
      to="/inventory/purchases-dashboard"
      title="Purchases Dashboard"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    <SidebarMenuItem
      to="/inventory/purchases"
      title="Purchases Orders"
      icon="credit-cart"
      fontIcon="bi-person"
    />

    {/* <SidebarMenuItem
      to="/inventory/purchases-report"
      title="Purchases Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
  </SidebarMenuItemWithSub>
);
