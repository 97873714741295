import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class OrganizationApi {
  private organization_url = "crm/organizations";

  createOrganization = async (data: any, withFile?: boolean) => {
    let results = await httpPost(this.organization_url, data, withFile);
    return this.extractOrganization(results);
  };

  //update Organization
  updateOrganization = async (
    id: number | string,
    data: any,
    withFile?: boolean
  ) => {
    let results = await httpPost(
      `${this.organization_url}/${id}`,
      data,
      withFile
    );
    return this.extractOrganization(results);
  };

  //Get all Organization
  pullAllOrganization = async (path: string) => {
    let results = await httpGet(this.organization_url + "?" + path);
    return this.extractOrganization(results);
  };

  exportAllOrganization = async (path: string, headers?: any) => {
    return httpGet(this.organization_url + "?" + path, headers);
  };

  //Get one Organization
  getOneOrganization = async (id: number | string) => {
    let results = await httpGet(`${this.organization_url}/${id}`);
    return this.justOrganizationData(this.extractOrganization(results));
  };
  openCSV = (id: number | string, headers: any) => {
    // return httpGet(`${this.url}/${id}`);
    return httpGet(`${this.organization_url}/${id}/csv`, headers);
    // return downloadAndOpenPdf(`${this.url}/${id}/pdf`);
  };
  //Get all Organization
  deleteOrganization = (id: number | string) => {
    return httpDelete(`${this.organization_url}/${id}`);
  };
  overrideSuspension = (
    id: number | string,
    data: { override_date: string }
  ) => {
    return httpPut(`${this.organization_url}/${id}/override-suspension`, data);
  };
  overrideMessage = (result: any) => {
    if (result.data && result.data.status) {
      return result.data.message;
    } else {
      return false;
    }
  };
  extractOrganization = (results: any) => {
    if (results.status && results.data) {
      return results.data.data;
    } else return false;
  };
  justOrganizationData = (results: any) => {
    if (results.organization) {
      return results.organization;
    } else return false;
  };
}
