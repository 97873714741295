/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { ShadowedCard } from "../../../../general-components/panels/shadowed-card";
import { HorizontalApart } from "../../../../general-components/simple-layouts/horizontal-apart";
import {
  CollapsiblePanel,
  PanelOpenButton,
} from "../../../../general-components/panels/collapsible-panel";
import { NormalDate } from "../../../../general-components/forms/date-element";
import { NormalSelect } from "../../../../general-components/forms/select-element";
import { NormalInput } from "../../../../general-components/forms/input-element";
import { CosApi } from "../../../../apis/cosApi";
import { CosType } from "./cos.type";
import { DeleteCos } from "./delete-cos";
import { AlignLeft } from "../../../../general-components/simple-layouts/align-left";
import {
  ModalOpener,
  SimpleModal,
} from "../../../../general-components/modals/simple-modals";
import { CreateCos } from "./create-cos";
import LoadingBars, {
  LoadingVerticalBars,
  TableStrippesLoader,
} from "../../../../general-components/loaders/simple-loader";
import { mis_date_from_string_using_timezone } from "../../../../utils/date-utils";
import { separateNumber } from "../../../../utils/number-utils";
import { TWODecimalPlaces } from "../../../Profile/components/util";
import { MISTable } from "../../../../general-components/tables/mis-table";
import { NavLink } from "react-router-dom";
const API = new CosApi();
function CostOfService() {
  const [searchIcons, setSearchIcon] = useState<Boolean>(false);
  const [cosList, setCosList] = useState<
    | {
        cos: CosType[];
        totals: {
          grand_total_ok: number;
          grand_total_unknown: number;
          grand_total_suspended: number;
          grand_total_waste: number;
          total_waste_percentage: number;
          grand_total: number;
          count: number;
        };
      }
    | undefined
  >(undefined);
  const [updateID, setUpdateID] = useState<string | number | undefined>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  //modal name
  let modalId = "modalCos";
  const pullCos = async (params?: string) => {
    try {
      setIsLoading(true);
      let results = await API.pullAllCostOfService(params);
      console.log(results);
      results = API.extractCos(results);
      console.log(results);
      if (results) {
        setCosList(results as any);
      } else {
        setCosList(undefined);
      }
    } catch (ex) {
      console.log(ex);
      setCosList(undefined); //we got error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    pullCos();
  }, []);

  const dataList: any = [];
  return (
    <>
      <div>
        <ShadowedCard>
          <HorizontalApart>
            <div className="text-start">
              <h1 className="fw-lighter text-gray-700">Cost of Service</h1>
              <ol className="breadcrumb text-muted fs-6 fw-bold">
                <li className="breadcrumb-item pe-3">
                  <a href="#" className="pe-3">
                    Customer Service
                  </a>
                </li>
                <li className="breadcrumb-item px-3 text-muted">
                  Cost of Service
                </li>
              </ol>
            </div>

            <AlignLeft>
              <ModalOpener
                target={modalId}
                styler="btn btn-sm btn-primary align-self-center"
                onClick={() => setUpdateID(undefined)}
              >
                New Cost Of Service
              </ModalOpener>
            </AlignLeft>
          </HorizontalApart>
        </ShadowedCard>

        <SimpleModal
          large
          target={modalId}
          title={updateID ? "Update CoS" : "Create CoS"}
        >
          {updateID ? (
            <>
              <h2>This works</h2>
            </>
          ) : (
            // <UpdateCostOfService
            //           id={updateID}
            //           callback={(updated: CosType) => {
            //             setCosList(
            //               cosList.map((data: CosType) => {
            //                 if (data.cos_id === updated.cos_id)
            //                   return updated;
            //                 return data;
            //               })
            //             );
            //           }}
            //         />
            <CreateCos
              callback={(data: CosType) => {
                if (data) {
                  console.log(data);
                  // setCosList([data, ...cosList]);
                }
              }}
            />
          )}
        </SimpleModal>

        <CollapsiblePanel
          iconState={searchIcons}
          setIconState={setSearchIcon}
          panelName="collapsiblePanel"
        >
          <NormalDate label="Date Open From" size="col-sm-3" />
          <NormalDate label="Date Open To" size="col-sm-3" />
          <NormalDate label="Date Closed From" size="col-sm-3" />
          <NormalDate label="Date Closed To" size="col-sm-3" />
          <NormalInput label="Ticket #" />
          <NormalInput label="Title" />
          <NormalInput label="Description" />
          <NormalSelect
            label="Reported Medium"
            size="col-sm-3"
            items={[
              "--Select Medium--",
              "Email",
              "Phone",
              "Visit",
              "Self Opened",
            ]}
          />

          <NormalSelect
            label="Ticket Status"
            size="col-sm-3"
            items={[
              "--Select Status--",
              "Open",
              "On Hold",
              "Closed",
              "Self Opened",
              "Cancelled",
            ]}
          />

          <NormalSelect
            label="State"
            size="col-sm-3"
            items={[
              "--Select state--",
              "First State",
              "Final State",
              "Middle State",
              "Confirmation",
            ]}
          />

          <NormalSelect label="Customer" items={["--"]} />

          <NormalSelect label="Department" items={["--"]} />

          <NormalSelect label="Members" items={["--"]} />

          <NormalSelect label="Problem" items={["--"]} />
          <NormalSelect label="Resolution" items={["--"]} />

          <div className="col-sm-2 mt-2">
            <button
              className="btn btn-sm btn-success mt-6"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Submit
            </button>
          </div>
        </CollapsiblePanel>

        <div className="d-flex justify-content-end px-1 mt-2">
          <PanelOpenButton
            iconState={searchIcons}
            setIconState={setSearchIcon}
            panelName="collapsiblePanel"
          />
          <button className="btn btn-secondary me-1 btn-sm align-self-center">
            Bulk Update
          </button>
          <button className="btn btn-primary btn-sm align-self-center">
            Export to CSV
          </button>
        </div>

        {/* Desktop view with ShadowedCard */}
        <div className="d-none d-md-block">
          <ShadowedCard shadow>
            <MISTable>
              <thead>
                <tr className="fw-bold text-muted bg-light px-3">
                  <th className="text-dark fw-bold px-4">
                    <strong>#</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>Document Date</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>Document No.</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>Provider</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>Start Date</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>End Date</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-100px">
                    <strong>OK</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>Waste</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-125px">
                    <strong>Total</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-100px">
                    <strong>Waste %</strong>
                  </th>
                  <th className="text-dark fw-bold min-w-150px">
                    <strong>User</strong>
                  </th>
                  <th className="text-dark fw-bold"></th>
                </tr>
              </thead>
              <tbody>
                <>
                  {isLoading ? (
                    <>
                      <TableStrippesLoader colspan={12} />
                    </>
                  ) : (
                    <>
                      {cosList &&
                        cosList.cos.map((item: CosType, idx: number) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              {mis_date_from_string_using_timezone(
                                item.provider_date
                              )}
                            </td>
                            <td>{item.cos_provider_reference}</td>
                            <td>{item.provider_name}</td>

                            <td>
                              {mis_date_from_string_using_timezone(
                                item.cos_date_start
                              )}
                            </td>
                            <td>
                              {mis_date_from_string_using_timezone(
                                item.cos_date_end
                              )}
                            </td>
                            <td>
                              {separateNumber(
                                parseInt(item.cos_total_ok as string)
                              )}
                            </td>

                            <td>{`${item.currency_name} ${separateNumber(
                              parseInt(item.cos_total_unknown as string)
                            )}`}</td>
                            <td>{`${item.currency_name} ${separateNumber(
                              parseInt(item.cos_total as string)
                            )}`}</td>
                            <td>
                              {TWODecimalPlaces(
                                parseFloat(item.cos_waste as string)
                              )}{" "}
                              %
                            </td>
                            <td>{item.user}</td>
                            <td>
                              {/* <DeleteCos
                                  id={item.id}
                                  delete_path=""
                                  callback={(id) => {
                                    setCosList(
                                      cosList.filter(
                                        (data: CosType) => data.cos_id !== id
                                      )
                                    );
                                  }}
                                /> */}
                              <NavLink to={`${item.cos_id}`}>
                                <i className="fa fa-eye me-1" />
                              </NavLink>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </>
              </tbody>
            </MISTable>
          </ShadowedCard>
        </div>

        {/* Mobile view without ShadowedCard */}
        <div className="d-block d-md-none">
          <div>
            <div className="card-body" style={{ padding: 0 }}>
              {isLoading ? (
                <TableStrippesLoader colspan={12} />
              ) : (
                <>
                  {cosList &&
                    cosList.cos.map((item: CosType, idx: number) => (
                      <div
                        key={idx}
                        className="card my-2 shadow-sm border-0"
                        style={{
                          marginBottom:
                            idx === cosList.cos.length - 1 ? "0" : "0.5rem",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <div className="card-body p-3">
                          {/* Header */}
                          <div className="d-flex justify-content-between mb-3">
                            <div>
                              <h6 className="mb-0">#{idx + 1}</h6>
                              <small className="text-muted"></small>
                            </div>
                          </div>

                          {/* Content */}
                          <div className="row g-3 mb-3">
                            <div className="col-6">
                              <div className="text-muted small">
                                Document Date
                              </div>
                              <div>
                                {mis_date_from_string_using_timezone(
                                  item.provider_date
                                )}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-muted small">
                                Document No.
                              </div>
                              <div>{item.cos_provider_reference}</div>
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col-12">
                              <div className="text-muted small">Provider</div>
                              <div>{item.provider_name}</div>
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col-6">
                              <div className="text-muted small">Start Date</div>
                              <div>
                                {mis_date_from_string_using_timezone(
                                  item.cos_date_start
                                )}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-muted small">End Date</div>
                              <div>
                                {mis_date_from_string_using_timezone(
                                  item.cos_date_end
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col-6">
                              <div className="text-muted small">OK</div>
                              <div>
                                {separateNumber(
                                  parseInt(item.cos_total_ok as string)
                                )}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-muted small">Waste</div>
                              <div>{`${item.currency_name} ${separateNumber(
                                parseInt(item.cos_total_unknown as string)
                              )}`}</div>
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col-6">
                              <div className="text-muted small">Total</div>
                              <div>{`${item.currency_name} ${separateNumber(
                                parseInt(item.cos_total as string)
                              )}`}</div>
                            </div>
                            <div className="col-6">
                              <div className="text-muted small">Waste %</div>
                              <div>
                                {TWODecimalPlaces(
                                  parseFloat(item.cos_waste as string)
                                )}{" "}
                                %
                              </div>
                            </div>
                          </div>

                          <div className="row g-3 mb-3">
                            <div className="col-12">
                              <div className="text-muted small">User</div>
                              <div>{item.user}</div>
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="text-end border-top pt-2">
                            <NavLink to={`${item.cos_id}`}>
                              <i className="fa fa-eye me-1" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>

        {/* Center aligned pagination */}
        <div className="d-flex justify-content-center">
          {/* ... existing Paginator component ... */}
        </div>
      </div>
    </>
  );
}

const CostOfServiceWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>CostOfService</PageTitle>
      <CostOfService />
    </>
  );
};

export { CostOfServiceWrapper, CostOfService };
