import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from "react";
import { MIS_BLUE } from "../../../_metronic/mis.themes";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sideChildren?: React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  title?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
  sideChildren,
  size = "md",
  className = "",
  title = "",
}) => {
  // Handle ESC key press
  React.useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEsc);
    }

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen, onClose]);

  // Handle click outside
  const modalRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  // Size classes mapping for Bootstrap
  const sizeClasses: any = {
    sm: "modal-sm",
    md: "", // default Bootstrap modal size
    lg: "modal-lg",
    xl: "modal-xl",
  };

  // Bootstrap classes for modal animation
  const modalClasses: string = `modal fade ${isOpen ? "show" : ""} d-block`;

  return (
    <>
      {/* Bootstrap backdrop */}
      <div className="modal-backdrop fade show" />

      {/* CustomModal */}
      <div
        className={modalClasses}
        // onClick={handleClickOutside}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div
          className={`modal-dialog ${sizeClasses[size]} ${className}`}
          ref={modalRef}
        >
          <div className="row">
            <div className={sideChildren ? "col-sm-8" : "col-sm-12"}>
              <div className="modal-content">
                {/* Close button */}
                {/* <button
              type="button"
              className="btn-close position-absolute"
              style={{ right: "1rem", top: "1rem" }}
              onClick={onClose}
              aria-label="Close"
            /> */}
                <div className="modal-header " style={{ background: MIS_BLUE }}>
                  <h2 className="modal-title text-light fw-600 fs-1">
                    {title}
                  </h2>
                  <button
                    type="button"
                    className="btn-close text-light"
                    // data-bs-dismiss="modal"
                    onClick={onClose}
                    aria-label="Close"
                  />
                </div>
                {/* CustomModal body */}
                <div className="modal-body">
                  <div className="text-start">{children}</div>
                </div>
              </div>
            </div>
            {sideChildren ? (
              <div className="col-sm-3 ms-1">
                <div className="modal-content">{sideChildren}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;

// Example usage:
const Example = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="p-3">
      <button onClick={() => setIsOpen(true)} className="btn btn-primary">
        Open CustomModal
      </button>

      <CustomModal isOpen={isOpen} onClose={() => setIsOpen(false)} size="lg">
        <h2 className="mb-3">CustomModal Title</h2>
        <p className="mb-3">This is the modal content.</p>
        <div className="d-flex justify-content-end gap-2">
          <button
            onClick={() => setIsOpen(false)}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              // Handle confirmation
              setIsOpen(false);
            }}
            className="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

// ============== MODAL HELPER ==============
interface ActiveContextType {
  isActive: boolean;
  Activate: () => void;
  DeActivate: () => void;
}

const ActiveContextSuspend = createContext<ActiveContextType | undefined>(
  undefined
);
const ActiveContextUnSuspend = createContext<ActiveContextType | undefined>(
  undefined
);
const ActiveContextTerminate = createContext<ActiveContextType | undefined>(
  undefined
);

const ActiveContextTransfer = createContext<ActiveContextType | undefined>(
  undefined
);

interface ActiveProviderProps {
  children: ReactNode;
}

export const ModalSuspendActiveProvider: FC<ActiveProviderProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false);
  const Activate = () => {
    setIsActive(true);
  };

  const DeActivate = () => {
    setIsActive(false);
  };

  return (
    <ActiveContextSuspend.Provider value={{ isActive, Activate, DeActivate }}>
      {children}
    </ActiveContextSuspend.Provider>
  );
};

export const ModalUnSuspendActiveProvider: FC<ActiveProviderProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false);
  const Activate = () => {
    setIsActive(true);
  };

  const DeActivate = () => {
    setIsActive(false);
  };

  return (
    <ActiveContextUnSuspend.Provider value={{ isActive, Activate, DeActivate }}>
      {children}
    </ActiveContextUnSuspend.Provider>
  );
};

export const ModalTerminateActiveProvider: FC<ActiveProviderProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false);
  const Activate = () => {
    setIsActive(true);
  };

  const DeActivate = () => {
    setIsActive(false);
  };

  return (
    <ActiveContextTerminate.Provider value={{ isActive, Activate, DeActivate }}>
      {children}
    </ActiveContextTerminate.Provider>
  );
};

export const ModalTransferActiveProvider: FC<ActiveProviderProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false);
  const Activate = () => {
    setIsActive(true);
  };

  const DeActivate = () => {
    setIsActive(false);
  };

  return (
    <ActiveContextTransfer.Provider value={{ isActive, Activate, DeActivate }}>
      {children}
    </ActiveContextTransfer.Provider>
  );
};

export const useActiveContextTransfer = (): ActiveContextType | undefined => {
  const context = useContext(ActiveContextTransfer);
  return context;
};
export const useActiveContextSuspend = (): ActiveContextType | undefined => {
  const context = useContext(ActiveContextSuspend);
  return context;
};

export const useActiveContextUnSuspend = (): ActiveContextType | undefined => {
  const context = useContext(ActiveContextUnSuspend);
  return context;
};

export const useActiveContextTerminate = (): ActiveContextType | undefined => {
  const context = useContext(ActiveContextTerminate);
  return context;
};
