import currency from "currency.js";
import { separateNumber } from "../../../utils/number-utils";

export const MONEY = (value: currency.Any, decimal_places = 2) =>
  separateNumber(
    currency(value, { symbol: "", precision: decimal_places }) as any
  );

export const TWODecimalPlaces = (value: number) =>
  Math.round(value * 100) / 100;

export const remove_compute_values = (values: any) =>
  Object.fromEntries(
    Object.entries(values).filter(([key]) => !key.startsWith("_"))
  );
