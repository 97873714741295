import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";
import { AxiosResponse } from "axios";
import { AssetType } from "../modules/Contacts/components/assets/types";

export class AssetsApi {
  private url: string = "crm/assets";
  createAssets = (data: any) => {
    return httpPost(this.url, data);
  };

  //update Assets
  updateAssets = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all Assets
  pullAllAssets = (params?: string) => {
    return httpGet(`${this.url}${params ? "?" + params : ""}`);
  };

  //Get one Assets
  getOneAssets = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  //Get all Assets
  deleteAssets = (id: number | string) => {
    return httpDelete(`${this.url}/${id}`);
  };

  extractAssets = (response: AxiosResponse): AssetType | null => {
    if (response.data && response.data.status) {
      return response.data.data as AssetType;
    } else {
      return null;
    }
  };

  errorMessage = (results: any) => {
    if (results.data && results.data.status === false) {
      return results.data.message;
    } else {
      return "An error have occured";
    }
  };
}
