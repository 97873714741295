import { FC, ReactNode, useEffect, useRef } from "react";
import { MIS_BLUE } from "../../../_metronic/mis.themes";
import { Modal } from "bootstrap";

interface ModalProps {
  target: string;
  children?: ReactNode; // Add this line for the 'children' prop
  title: string;
  large?: boolean;
  ref?: any;
  close?: () => void;
  textAlign?: "text-start" | "text-center" | "text-end";
  minHeight?: string;
}

export const SimpleModal: FC<ModalProps> = ({
  children,
  target,
  title,
  large,
  ref,
  textAlign,
  minHeight,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    const handleModalHidden = () => {
      // Select all modal-backdrop elements
      const backdrops = document.querySelectorAll(".modal-backdrop");
      backdrops.forEach((backdrop) => {
        backdrop.parentNode?.removeChild(backdrop); // Remove each backdrop
      });
    };

    //add event listener for modal hidden event
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalHidden);
    }

    // Cleanup event listener on unmount
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalHidden);
      }
    };
  }, []);
  return (
    <>
      <div
        className="modal fade"
        id={target}
        tabIndex={-1}
        aria-labelledby={`${target}Label`}
        aria-hidden="true"
        ref={modalRef}
        // ref={ref}
      >
        <div
          className={`modal-dialog ${
            large
              ? "modal-dialog-centered modal-xl modal-dialog-scrollable"
              : ""
          }`}
        >
          <div className="modal-content" style={{ minHeight }}>
            <div className="modal-header " style={{ background: MIS_BLUE }}>
              <h2
                className="modal-title text-light fw-600 fs-1"
                id={`${target}Label`}
              >
                {title}
              </h2>
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className={`modal-body ${textAlign}`}>{children}</div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type ModalOpenerType = {
  styler?: string;
  target?: string;
  label?: string;
  children?: ReactNode;
  onClick?: () => void;
};
export const ModalOpener: FC<ModalOpenerType> = ({
  styler,
  target,
  label,
  children,
  onClick,
}) => (
  <button
    type="button"
    className={`${styler ? styler : "btn btn-primary"}`}
    data-bs-toggle="modal"
    data-bs-target={`#${target}`}
    onClick={() => {
      if (onClick) {
        onClick();
      }
    }}
  >
    {children}
  </button>
);

//REMOVE BACKDROP
export const removeBackdrop = () => {
  const modalBackdrop = document.querySelector(".modal-backdrop");
  if (modalBackdrop) {
    modalBackdrop.remove();
  }
};

//we'll need this programmatically opening a modal
export const openModal = (modalRef: any) => {
  const modalElement = modalRef.current;
  if (modalElement) {
    // Open the Bootstrap modal programmatically
    // Assuming you've set the id of the modal container as "exampleModal"
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  }
};

export const closeModal = (modalRef: any, selector: string) => {
  console.log("selector", selector);
  console.log(modalRef);
  const modalElement = modalRef.current;
  if (modalElement) {
    console.log("Is this reached??");
    // Trigger click event on the close button to close the modal
    const closeButton = modalElement.querySelector(selector);
    if (closeButton) {
      closeButton.click();
    }
  }
};

//Programmatically close a modal
export const generalCloseModal = (modalID: string) => {
  const modalElement: any = document.getElementById(modalID);
  const bootstrapModal = Modal.getInstance(modalElement);
  bootstrapModal?.hide();
};
