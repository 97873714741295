import clsx from "clsx";
import {
  ChangeEvent,
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { FieldProps } from "formik";
import { mis_date_put_day_first } from "../../utils/date-utils";
type PropType = {
  label: string;
  size?: string;
  marginTop?: string;
  type?: string;
  error?: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  fieldprops?: any;
  name?: string;
  touched?: any;
  value?: string;
  touched_no_error?: any;
  placeholder?: string;
  actualValue?: string;
};
export const NormalInput = forwardRef<
  HTMLInputElement,
  PropType & { min?: number }
>(
  (
    {
      label,
      size,
      marginTop,
      type,
      onChange,
      error,
      actualValue,
      name,
      value,
      fieldprops,
      touched,
      touched_no_error,
      placeholder,
    }: PropType,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    if (onChange)
      return (
        <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
          <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
          <input
            className={`form-control form-control-sm ${error && "red-border"}`}
            type={type || "text"}
            id=""
            ref={inputRef}
            name={name}
            defaultValue={value}
            value={actualValue}
            onChange={onChange}
            placeholder={placeholder}
          />
          {error ? <span className="invalid-feedback">{error}</span> : null}
        </div>
      );

    return (
      <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
        <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
        <input
          className={clsx(
            `form-control form-control-sm ${
              touched && error && !touched_no_error && "red-border"
            }`,
            { "is-invalid": touched }
          )}
          type={type || "text"}
          ref={inputRef}
          id=""
          name={name}
          {...fieldprops}
        />
        {touched && error && !touched_no_error ? (
          <span className="invalid-feedback">{error}</span>
        ) : null}
      </div>
    );
  }
);

//=============== validated input ==================
interface CustomInputProps {
  //=>read field and form type on usable input with formik yup validation
  size?: string;
  type?: string;
  disabled?: boolean;
  marginTop?: string;
  field: {
    name: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };
  form: {
    touched: { [field: string]: boolean };
    errors: { [field: string]: string };
  };
  label: string;
  formatedInput?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const ValidatedInput: FC<
  CustomInputProps & { min?: number; max?: number; onBlur?: (e: any) => void }
> = ({
  field,
  form,
  label,
  size,
  disabled,
  marginTop,
  type,
  min,
  max,
  formatedInput,
  onBlur,
}) => {
  if (formatedInput) {
    field = { ...field, onChange: (e) => formatedInput(e) };
  }
  return (
    <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
      <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
      {/* <label htmlFor={field.name}>{label}</label> */}
      <input
        min={min}
        maxLength={max}
        id={field.name}
        type={type || "text"}
        {...field}
        className={`form-control form-control-sm ${
          form?.touched?.[field.name] &&
          form?.errors?.[field.name] &&
          "red-border"
        }`}
        disabled={disabled}
        onBlur={onBlur}
        value={field.value}
        autoComplete="off"
      />
      {form?.touched?.[field.name] && form?.errors?.[field.name] && (
        <div style={{ color: "red" }}>{form?.errors?.[field.name]}</div>
      )}
    </div>
  );
};

export const ValidatedSelectInput: FC<{
  value: string | number;
  onChange: (value: string) => void;
  onBlur: () => void;
  options: { value: string; label: string }[];
  label?: string;
  size?: string;
  marginTop?: string;
  error?: string;
  preselected_id?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  dummyDefault?: string;
  showDefault?: boolean;
}> = ({
  value,
  onChange,
  onBlur,
  options,
  label,
  size,
  marginTop,
  hideLabel,
  error,
  preselected_id,
  disabled,
  dummyDefault, //that first default Value if you have other string than the word Default, but the value is null/ empty string
  showDefault,
}) => {
  return (
    <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
      {hideLabel ? null : (
        <span className="fs-7 text-dark fw-bold ms-1">{label} </span>
      )}

      <select
        value={preselected_id || value} //TODO: THIS WAS A VALUE WE CHANGED TO DEFAULT VALUE
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        className={`form-control form-control-sm ${error && "red-border"}`}
      >
        {/* <option value="" label={dummyDefault || "Default"} /> */}
        {showDefault ? <option value="">{dummyDefault}</option> : null}
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </select>
      {error ? <div style={{ color: "red" }}>{error}</div> : null}
    </div>
  );
};

/**================= VALIDATED INPUT WITH DATA ================ */
export const ValidatedSelectInputWithData: FC<{
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  options: { value: string; label: string }[];
  label?: string;
  size?: string;
  marginTop?: string;
  error?: any;
  loading: boolean;
  data?: any;
  value_name?: any;
  key_name?: any;
  id_name?: any;
}> = ({
  value,
  onChange,
  onBlur,
  options,
  label,
  size,
  marginTop,
  error,
  loading,
  data,
  value_name,
  key_name,
  id_name,
}) => {
  return (
    <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
      <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>

      {loading ? (
        <span>loading...</span>
      ) : (
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          className={`form-control form-control-sm ${error && "red-border"}`}
        >
          <option value="" label="Select an option" />
          {data &&
            data.map((option: any) => (
              <option
                key={option[id_name]}
                value={option[key_name]}
                label={option[value_name]}
              />
            ))}
        </select>
      )}
      {error ? <div style={{ color: "red" }}>{error}</div> : null}
    </div>
  );
};

//read: good distribution property for the typescript
type UnitSuffix = "px" | "%" | "rem" | "em" | "vh" | "vw" | "vmin" | "vmax";
type DimensionValue = string & { __isDimensionValue: never }; //Maker type
type DimensionString = `${number}${UnitSuffix}` | DimensionValue;

export const ValidatedTextArea: FC<
  CustomInputProps & {
    height?: DimensionString;
    disabled?: boolean;
    placeholder?: string;
  }
> = ({
  field,
  form,
  label,
  size,
  marginTop,
  height,
  disabled,
  type,
  placeholder,
}) => (
  <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
    <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
    {/* <label htmlFor={field.name}>{label}</label> */}
    <textarea
      id={field.name}
      style={{ height: height || "100px" }}
      {...field}
      className={`form-control form-control-sm ${
        form.touched[field.name] && form.errors[field.name] && "red-border"
      }`}
      disabled={disabled}
      placeholder={placeholder}
    />
    {form.touched[field.name] && form.errors[field.name] && (
      <div style={{ color: "red" }}>{form.errors[field.name]}</div>
    )}
  </div>
);
