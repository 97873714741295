/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/Auth";
import { toAbsoluteUrl } from "../../../helpers";
import CustomModal from "../../../../app/general-components/elements/custom-modal";
/**=================== THIS IS ONLY FOR USER HEADER MENU =================== */

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState<any>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newErrors: any = {};

    // Validate fields
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Handle form submission here
    console.log("Form submitted:", formData);

    // Reset form
    setFormData({
      subject: "",
      message: "",
    });
  };
  return (
    <>
      <CustomModal
        title="Support"
        isOpen={showSupportModal}
        onClose={() => setShowSupportModal(false)}
        size="lg"
      >
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                {/* Subject field */}
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.subject ? "is-invalid" : ""
                    }`}
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Enter subject"
                  />
                  {errors.subject && (
                    <div className="invalid-feedback">{errors.subject}</div>
                  )}
                </div>

                {/* Message field */}
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className={`form-control ${
                      errors.message ? "is-invalid" : ""
                    }`}
                    id="message"
                    name="message"
                    rows={5}
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your message"
                  ></textarea>
                  {errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </CustomModal>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {currentUser?.first_name} {currentUser?.last_name}
                {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
              </div>
              <a
                href="#"
                className="fw-bold text-muted text-hover-primary fs-7"
              >
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className="separator my-2"></div>

        <div className="menu-item px-5">
          {/* <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link> */}
          <a
            className="menu-link px-5 text-hover-primary"
            // data-bs-toggle="modal"
            // data-bs-target="#change_password"
            onClick={() => setShowSupportModal(true)}
          >
            Contact support
          </a>
        </div>

        <div className="menu-item px-5">
          {/* <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link> */}
          <a
            className="menu-link px-5 text-hover-primary"
            data-bs-toggle="modal"
            data-bs-target="#change_password"
          >
            Change Password
          </a>
        </div>

        {/* The modal above can be found in the App.tsx file */}

        <div className="menu-item px-5">
          <a onClick={logout} className="menu-link px-5 text-hover-danger">
            Sign Out
          </a>
        </div>

        {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

        {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

        {/* <div className='separator my-2'></div>

      <Languages /> */}

        {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
      </div>
    </>
  );
};

export { HeaderUserMenu };
