import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/Auth";
import { useDispatch } from "react-redux";
import { startSettingFetching } from "./redux-store/settings";
import { PreferenceProvider } from "./higher-order-components/context-stores/preference.context";

const App = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   // dispatch(startSettingFetching());
  // }, []);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <PreferenceProvider>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />

              <div className="modal fade" tabIndex={-1} id="change_password">
                <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="d-flex justify-content-center p-3">
                        <span className="fs-3">
                          Are you sure you want to change your password? We'll
                          send you a link to follow the instructions.
                        </span>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel!
                      </button>
                      <button type="button" className="btn btn-danger">
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </PreferenceProvider>
    </Suspense>
  );
};

export { App };
