import axios from "axios";
import { BASE_URL } from "../modules/Profile/components/constants";

export async function downloadFile(
  link: string,
  fileName: string
): Promise<void> {
  try {
    const response = await axios.get(`${BASE_URL}${link}`, {
      responseType: "blob",
    });

    // Create a blob URL for the blob data
    const blobUrl = URL.createObjectURL(response.data);

    // Create a link element
    const downloadLink = document.createElement("a");

    // Set the download link attributes
    downloadLink.href = blobUrl;
    downloadLink.download = fileName;

    // Append the link to the document
    document.body.appendChild(downloadLink);

    // Trigger a click event on the link to start the download
    downloadLink.click();

    // Remove the link from the document
    document.body.removeChild(downloadLink);

    // Clean up the blob URL
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

// Example usage
// const fileLink = 'https://example.com/path/to/file.pdf';
// const fileName = 'downloaded_file.pdf';

// downloadFile(fileLink, fileName);

//================= OPEN PDF ON THE SIDE =============
export const downloadAndOpenPdf = async (url: string) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    const blobUrl = URL.createObjectURL(response.data);
    window.open(blobUrl, "_blank");
    setTimeout(() => URL.revokeObjectURL(blobUrl), 10000);
  } catch (error) {
    console.error("Error fetching PDF:", error);
  }
};

export const openPDF = async (api: any, id: string, filename: string) => {
  let response = await api(id, {
    responseType: "blob", // Ensures we get the binary data
  });
  // let response = await InvApi.openPDF(
  //   item.invoice_id as string,
  //   {
  //     responseType: "blob", // Ensures we get the binary data
  //   }
  // );

  const blob = new Blob([response.data], {
    type: "application/pdf",
  });

  //creating URL for the blob
  const blobUrl = URL.createObjectURL(blob);
  // Creating a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = `${filename}-${id}.pdf`; // Set desired file name
  // document.body.appendChild(link);
  // link.click();
  window.open(blobUrl, "_blank");
};

export function saveBlob(blob: any, filename = "export.csv") {
  // Create blob URL
  const url = window.URL.createObjectURL(blob);

  // Create temporary link element
  const link = document.createElement("a");
  link.href = url;
  link.download = filename; // Set the filename

  // Add to document, click, and remove
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the blob URL
  window.URL.revokeObjectURL(url);
}
