export const removeCharacter = (character: any, text: string) => {
  /**
   * character could be _,$,% < >
   * string is your text to be removed some un necessary strings
   */
  if (!text) return text;
  return text.replaceAll(character, " ");
};

export const camelCaseWithSpace = (text: string) => {
  let words = text.split(" ");
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export function getFirstWord(input: string): string | null {
  if (!input && !input.trim()) return null;

  //Split the input string by whitespace
  const words = input.split(/\s+/);
  if (!words.length) return null;
  //return the first wod index 0
  return words[0];
}

export function getLastWord(input: string): string | null {
  if (!input && !input.trim()) return null;

  //Split the input string by whitespace
  const words = input.split(/\s+/);
  if (!words.length) return null;
  //return the first wod index 0
  return words[words.length - 1];
}

export function textToSlug(input: string = ""): string {
  return input.replace(/\s+/g, "-").toLowerCase();
}

export const getFirstFewCharacters = (input: string, firstCount: number) => {
  return input.substring(0, firstCount);
};

export function generatePassword(length = 10) {
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const special = "!@#$%^&*()_+-=[]{}|;:,.<>?";

  const allChars = uppercase + lowercase + numbers + special;

  let password = "";

  // Ensure at least one character from each category
  password += uppercase[Math.floor(Math.random() * uppercase.length)];
  password += lowercase[Math.floor(Math.random() * lowercase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += special[Math.floor(Math.random() * special.length)];

  // Fill the rest of the password
  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the password
  return password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
}
