import React, { createContext, useContext, useState, useEffect } from "react";

// Define types
interface PreferenceData {
  [key: string]: any;
}

interface PreferenceContextType {
  data: PreferenceData;
  setPrefValue: <T>(key: string, value: T) => void;
  getPrefValue: <T>(key: string) => T | undefined;
  removePrefValue: (key: string) => void;
  clearPrefAll: () => void;
}

const STORAGE_AREA = "user_preferences";
interface PreferenceProviderProps {
  children: React.ReactNode;
}

// Create context with type
const PreferenceContext = createContext<PreferenceContextType | undefined>(
  undefined
);

// Create provider component
export const PreferenceProvider: React.FC<PreferenceProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<PreferenceData>(() => {
    try {
      const item = localStorage.getItem(STORAGE_AREA);
      return item ? JSON.parse(item) : {};
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return {};
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(STORAGE_AREA, JSON.stringify(data));
    } catch (error) {
      console.error("Error writing to localStorage:", error);
    }
  }, [data]);

  const setPrefValue = <T,>(key: string, value: T): void => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const getPrefValue = <T,>(key: string): T | undefined => {
    return data[key] as T;
  };

  const removePrefValue = (key: string): void => {
    setData((prevData) => {
      const newData = { ...prevData };
      delete newData[key];
      return newData;
    });
  };

  const clearPrefAll = (): void => {
    setData({});
  };

  const value: PreferenceContextType = {
    data,
    setPrefValue,
    getPrefValue,
    removePrefValue,
    clearPrefAll,
  };

  return (
    <PreferenceContext.Provider value={value}>
      {children}
    </PreferenceContext.Provider>
  );
};

// Custom hook with type safety
export const usePreference = (): PreferenceContextType => {
  const context = useContext(PreferenceContext);
  if (!context) {
    throw new Error("useStorage must be used within a PreferenceProvider");
  }
  return context;
};
