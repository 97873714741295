/**checking if a value is plain object not even an array or other value type */
export function isPlainObject(variable: any): boolean {
  return (
    typeof variable === "object" &&
    variable !== null &&
    variable.constructor === Object
  );
}

/**
 * Now trim of some attributes from object and return new object
 * and attr can be an array of just simple string.... :))
 */
export function trimObject(
  obj: Record<string, any>,
  attr: string | string[]
): Record<string, any> {
  return Object.keys(obj).reduce((acc: any, key: string) => {
    if (Array.isArray(attr) && !attr.some((element) => key.includes(element))) {
      acc[key] = obj[key];
    }
    if (!Array.isArray(attr) && !key.includes(attr)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export const getError = (ex: any) =>
  ex?.response?.data?.message || "An error has occured!";

export const complexError = (ex: any) => {
  if (typeof ex === "object" && ex.connectionName === "realm") {
    return ex.errorInfo[ex.errorInfo.length - 1];
  }

  return getError(ex);
};
